



.dash_div{
    background: #fff;
    border-radius: 5px;
    padding: 25px;
    box-shadow: 0px 30px 70px rgba(26, 55, 78, 0.10);
}

.dash_request_container{
    background: #fff;
    border-radius: 12px;
    box-shadow: 0px 30px 70px rgba(26, 55, 78, 0.2);
    padding: 10px;
}


.analytics_div{
    background: #FFFFFF;
    border-radius: 10px;
    padding: 20px;
}
.analytics_title{
    color: rgba(3, 2, 41, 0.7);
    font-weight: bold;
}



/* ------------------MEDIA QUERY SECTION ------------------------- */
/* Media query for tablets and screens higher */
@media (min-width: 768px) {
}


/* sreens higher than tablets */
@media (min-width: 1024px) {
    
}



/* large screens */
@media (min-width: 1536px) {
}
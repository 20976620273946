
.settings_card{
    background: #FFFFFF;
    box-shadow: 0px 30px 70px rgba(26, 55, 78, 0.2);
    border-radius: 10px;
    padding: 40px 20px;
}

.title{
    color: rgba(26, 55, 78, 0.8);
    font-family: 'Graphix';
    /* text-align: center; */
}


.input_container label{
    font-size: 12px;
    color:  rgba(26, 55, 78, 0.8);
    font-family: 'Averta';
}

.input_style{
    border: 1px solid #F4F2F2;
    border-radius: 6px;
    font-size: 14px;
    color: #1A374E;
    width: 100%;
    padding: 14px 20px;
    background: #F4F2F2;
    appearance: none;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.input_style:focus{
    border: 1px solid #1A374E;
    background: white;
    box-shadow: none;
    outline: 0 none;
  }
  
  .input_style::placeholder{
    color:rgba(26, 55, 78, 0.5);
    font-size: 14px;
    font-family: 'Averta';
  }

  .btn_change{
    padding: 14px 40px;
    background: #1A374E;
    border: 1px solid #1A374E;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    width: 100%;
    font-family: 'GraphixMedium';
  }

  .btn_change:hover{
    color: #fff;
    background: rgba(26, 55, 78, 0.9);
  }



/* ------------------MEDIA QUERY SECTION ------------------------- */
/* Media query for tablets and screens higher */
@media (min-width: 768px) {
}


/* sreens higher than tablets */
@media (min-width: 1024px) {  
 .settings_card{
     background: #FFFFFF;
     box-shadow: 0px 30px 70px rgba(26, 55, 78, 0.2);
     border-radius: 10px;
     padding: 40px 80px;
     max-width: 48rem;
     margin: auto;
 }
}



/* large screens */
@media (min-width: 1536px) {
}
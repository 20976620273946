


.trades_div{
    background: #fff;
    border-radius: 12px;
    box-shadow: 0px 30px 70px rgba(26, 55, 78, 0.2);
    padding: 10px;
}

.recent_table_row{
    color: rgba(26, 55, 78, 0.9);
    font-family: 'Averta';
}

.trade_filter_container{
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
}

.transaction_search{
    display: flex;
}

.search_container{
    width: 100%;
}


.input_container label{
    font-size: 12px;
    color:  rgba(26, 55, 78, 0.8);
    font-family: 'Averta';
}

.input_style{
    border: 1px solid rgba(1,34,101,.2);
    border-radius: 5px;
    font-size: 14px;
    color: #1A374E;
    width: 100%;
    padding: 14px 20px;
    background: #fff;
    appearance: none;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.input_style:focus{
    border: 1px solid #1A374E;
    background: white;
    box-shadow: none;
    outline: 0 none;
  }
  
  .input_style::placeholder{
    color:rgba(26, 55, 78, 0.5);
    font-size: 14px;
    font-family: 'Averta';
  }

.filter_div{
    border:  1px solid #1A374E;
    border-radius: 5px;
     padding: 14px 45px;
     color: #333A43;
     transition: all 0.15s ease-in-out;
    width: 100%;
    font-size: 14px;
}


.filter_input_style{
    border: 1px solid #F7F7F8;
    border-radius: 5px;
    font-size: 14px;
    color: #1A374E;
    width: 100%;
    padding: 14px 20px;
    background: #F7F7F8;
    appearance: none;
    cursor: pointer;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.filter_input_style:focus{
    border: 1px solid #1A374E;
    background: white;
    box-shadow: none;
    outline: 0 none;
  }
  
.filter_input_style::placeholder{
    color:rgba(26, 55, 78, 0.5);
    font-size: 14px;
    font-family: 'Averta';
  }

  .btn_filter{
    padding: 14px 40px;
    background: #1A374E;
    border: 1px solid #1A374E;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    width: 100%;
    font-family: 'GraphixMedium';
  }

  .btn_filter:hover{
    color: #fff;
    background: rgba(26, 55, 78, 0.9);
}


.details_card{
    background: #FFFFFF;
    box-shadow: 0px 30px 70px rgba(26, 55, 78, 0.2);
    border-radius: 10px;
    padding: 40px 20px;
}

.title{
    color: rgb(45, 45, 45);
}

.details_caption{
    color: #2C3A50;
    font-family: 'GraphixMedium';
}

.details_text{
    font-size: 14px;
    margin-bottom: 0px;
}

.goback_div{
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #F7931A;
    text-decoration: none;
}

.goback_div:hover{
    color: #F7931A;
    text-decoration: none;
}

.status_div{
    display: flex;
}

.failed_icon{
    color: #eb5757;
    background: rgba(255, 143, 107, 0.1);
    border-radius: 100px;
    padding: 5px 20px;
    font-size: 14px;
}

.pending_icon{
    color: #FFD66B;
    background: rgba(255, 214, 107, 0.1);
    border-radius: 100px;
    padding: 5px 20px;
    font-size: 14px;   
}

.completed_icon {
    color: #9CC031;
    background: rgba(156, 192, 49, 0.1);
    border-radius: 100px;
    padding: 5px 20px;
    font-size: 14px;   
}





/* ------------------MEDIA QUERY SECTION ------------------------- */
/* Media query for tablets and screens higher */
@media (min-width: 768px) {
}


/* sreens higher than tablets */
@media (min-width: 1024px) {
    .trade_filter_container{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding: 25px 20px 0 0;
    }
    .input_style{
        width: 500px;
    }
    .details_card{
        background: #FFFFFF;
        box-shadow: 0px 30px 70px rgba(26, 55, 78, 0.2);
        border-radius: 10px;
        padding: 50px 80px;
        max-width: 48rem;
        margin: auto;
    }
    
}



/* large screens */
@media (min-width: 1536px) {
}
*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

/* Graphix */
@font-face {
  font-family: "Graphix";
  src: local("GraphikRegular"), url(./fonts/Graphik/GraphikRegular.otf);
}

@font-face {
  font-family: "GraphixBold";
  src: local("GraphikBold"), url(./fonts/Graphik/GraphikBold.otf);
}

@font-face {
  font-family: "GraphixSemibold";
  src: local("GraphikSemibold"), url(./fonts/Graphik/GraphikSemibold.otf);
}

@font-face {
  font-family: "GraphixMedium";
  src: local("GraphikMedium"), url(./fonts/Graphik/GraphikMedium.otf);
}

@font-face {
  font-family: "GoodTimes";
  src: local("GoodTimes"), url(./fonts/goodtimes.otf);
}

@font-face {
  font-family: "Averta";
  src: local("Averta"), url(./fonts/AvertaRegular.ttf);
}

html{
  scroll-behavior: smooth;
}


body {
  font-family: "Graphix";
}

/* my container class */
.contain {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.contain-side {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 25px;
  padding-right: 25px;
  position: relative;
}



@media (min-width: 640px) {
  .contain {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .contain {
    padding-left: 3rem;
    padding-right: 3rem;
    max-width: 768px;
  }
}

.rdt_TableHeader {
  color: rgba(0, 0, 0, 0.6) !important;
  font-family: 'GraphixMedium' !important;
  font-size: 18px !important;
}

.rdt_TableHeadRow{
  color: #1A374E !important;
  font-family: 'GraphixMedium';
}

.pending_icon{
  color: #FFD66B;
  background: transparent;
  border-radius: 100px;
  padding: 5px 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Averta';
}


.completed_icon{
  color: #9CC031;
  background: transparent;
  border-radius: 100px;
   padding: 5px 20px;
   white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.failed_icon{
color: #FF8F6B;
  background: transparent;
  border-radius: 100px;
   padding: 5px 20px;
   white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;  
}

.btn-view{
  border: 1px solid rgba(26, 55, 78, 0.9);
  color: rgba(26, 55, 78, 0.9);
  transition: all .15s ease-in;
}

.btn-view:hover{
  background: rgba(26, 55, 78, 1);
  color: white;
}

.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none;
}


.react-confirm-alert-body{
  font-size: 14px;
  font-family: 'Graphix' !important;
}

.react-confirm-alert-body > h1{
  font-size: 1.6em;
}



@media (min-width: 1024px) {
  .contain {
    max-width: 1024px;
  }
  .pending_icon{
   background: rgba(255, 214, 107, 0.1)
  }
  .completed_icon{
    background: rgba(156, 192, 49, 0.1);
  }
  .failed_icon{
    background: rgba(255, 143, 107, 0.1);
  }
}

@media (min-width: 1280px) {
  .contain {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .contain {
    max-width: 1536px;
  }
}




.trades_div{
    background: #fff;
    border-radius: 12px;
    box-shadow: 0px 30px 70px rgba(26, 55, 78, 0.2);
    padding: 10px;
}

.recent_table_row{
    color: rgba(26, 55, 78, 0.9);
    font-family: 'Averta';
}

.trade_filter_container{
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
}

.transaction_search{
    display: flex;
}

.search_container{
    width: 100%;
}


.input_container label{
    font-size: 12px;
    color:  rgba(26, 55, 78, 0.8);
    font-family: 'Averta';
}

.input_style{
    border: 1px solid rgba(1,34,101,.2);
    border-radius: 5px;
    font-size: 14px;
    color: #1A374E;
    width: 100%;
    padding: 14px 20px;
    background: #fff;
    appearance: none;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.input_style:focus{
    border: 1px solid #1A374E;
    background: white;
    box-shadow: none;
    outline: 0 none;
  }
  
  .input_style::placeholder{
    color:rgba(26, 55, 78, 0.5);
    font-size: 14px;
    font-family: 'Averta';
  }

.filter_div{
    border:  1px solid #1A374E;
    border-radius: 5px;
     padding: 14px 45px;
     color: #333A43;
     transition: all 0.15s ease-in-out;
    width: 100%;
    font-size: 14px;
}


.filter_input_style{
    border: 1px solid #F7F7F8;
    border-radius: 5px;
    font-size: 14px;
    color: #1A374E;
    width: 100%;
    padding: 14px 20px;
    background: #F7F7F8;
    appearance: none;
    cursor: pointer;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.filter_input_style:focus{
    border: 1px solid #1A374E;
    background: white;
    box-shadow: none;
    outline: 0 none;
  }
  
.filter_input_style::placeholder{
    color:rgba(26, 55, 78, 0.5);
    font-size: 14px;
    font-family: 'Averta';
  }

  .btn_filter{
    padding: 14px 40px;
    background: #1A374E;
    border: 1px solid #1A374E;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    width: 100%;
    font-family: 'GraphixMedium';
  }

  .btn_filter:hover{
    color: #fff;
    background: rgba(26, 55, 78, 0.9);
}


.tab_div{
    margin-top: 10px;
    background: rgba(247, 147, 26, 0.05);
    border: 1px solid rgba(26, 55, 78, 0.1);
    box-sizing: border-box;
    border-radius: 10px;
    padding: 5px 8px;
}

.tab_list{
    list-style: none;
    margin-bottom: 0px;
    display: flex;
}

.tab_list li{
    flex-basis: 50%;
    cursor: pointer;
}

.tab{
    margin-bottom: 0px;
    color: rgba(26, 55, 78, 0.8);
    font-family: 'Averta';
    text-align: center;
    padding: 10px;
}

.tab_active{
    background: #F7931A;
    border-radius: 10px;
    color: white;
}

.details_card{
    background: #FFFFFF;
    box-shadow: 0px 30px 70px rgba(26, 55, 78, 0.2);
    border-radius: 10px;
    padding: 40px 20px;
}

.title{
    color: rgb(45, 45, 45);
}

.details_caption{
    color: #2C3A50;
    font-family: 'GraphixMedium';
}

.details_text{
    font-size: 14px;
    margin-bottom: 0px;
}

.goback_div{
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #F7931A;
    text-decoration: none;
}

.goback_div:hover{
    color: #F7931A;
    text-decoration: none;
}

.status_div{
    display: flex;
}

.failed_icon{
    color: #eb5757;
    background: rgba(255, 143, 107, 0.1);
    border-radius: 100px;
    padding: 5px 20px;
    font-size: 14px;
}

.pending_icon{
    color: #FFD66B;
    background: rgba(255, 214, 107, 0.1);
    border-radius: 100px;
    padding: 5px 20px;
    font-size: 14px;   
}

.completed_icon {
    color: #9CC031;
    background: rgba(156, 192, 49, 0.1);
    border-radius: 100px;
    padding: 5px 20px;
    font-size: 14px;
}


.cardImage{
    box-sizing: border-box;
    height: 72px;
    max-width: 100%;
}


.cta_btn{
    display: flex;
    justify-content: center;
}

.btn_approve{
    background: #1A374E;
    border-radius: 5px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    margin-right: 20px;
    min-width: 140px;
    padding: 12px 40px;
}

.btn_approve:hover{
    color: #fff;
    background: rgba(26, 55, 78, 0.9);
}

.btn_decline{
    background: #a3000e;
    border-radius: 5px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    min-width: 140px;
    padding: 12px 40px;
}

.btn_decline:hover{
    color: #fff;
    background: rgba(163, 0, 14, 0.9);
}

.cursor_pointer {
    cursor: pointer;
}

.input_group{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}

.input_group>.custom_file {
    display: -ms-flexbox;
    display: flex;
    /* border: 1px solid rgba(8, 152, 215, 0.2); */
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
}
.custom_file, .custom_file_input {
    height: calc(49px + 2px);
    cursor: pointer;
}
.custom_file_input {
    height: calc(49px + 2px);
    cursor: pointer;
}
.custom_file_input {
    position: relative;
    z-index: 2;
    width: 100%;
    margin: 0;
    opacity: 0;
}
.custom_file_label {
    padding: 14px 80px 14px 18px;
    height: calc(49px + 2px);
}
.custom_file_label {
    position: absolute;
    /* top: 0; */
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    left: 0px;
    z-index: 1;
    line-height: 1.5;
    color: #1A374E;
    background: #F7F7F8;
    border: 1px solid #F7F7F8;
    border-radius: .25rem;
    cursor: pointer;
}
.custom_file_label::after {
    position: absolute;
    /* top: 5px; */
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    z-index: 3;
    display: block;
    content: "Browse";
    background: transparent;
    border-left: none;
    border-radius: 0 .25rem .25rem 0;
    color: #1A374E;
}

.custom_file_input:focus {
    border: none;
    box-shadow: none;
    outline: 0 none;
}

.fileupload{
    margin-top: 10px;
    position: relative;
}
.imageFile{
    position: relative;
    width: fit-content;
    margin: 0 15px 15px 0;
}
.imageFile .close_icon {
    position: absolute;
    top: 0px;
    right: -6px;
    cursor: pointer;
    z-index: 1;
}
.close_icon {
    width: 22px;
    height: 22px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAAAXNSR0IArs4c6QAAAhtJREFUOBG1lc9LAlEQx2clDUUhA1ESAiHRi0H37L6esvVPCfobhI52tj+g7cfJztk93JNiIASmsqCBoaTJNt/VlbXeVhbOYd9j3sznzZs3b1YiB2k0Ghv9fj/Ly4okSbuGYYRgynOd5xpP1UAgcBWLxV6g/yzSZ0W9Xl8fDAbHDDjx+/1SMBj0e71ecrvdpul4PKbhcEi9Xu+Vhfcw8j6f7zQej7/ZWQvgarW6NRqNSgzciUajPo/HY7f9MmdbajabA97gkW3lZDL5bBnNwTPoQyQS2QyFQmuWwW9GXdff2+12l+F7FtwFRxwfkf4FCn8EAl8wwILOBCOnOP6ykQJgCXzBAAs6aXb7T4lEIvBTTi2I04ic12q1PlfLtgslhdv/LxSbgQEWmEiFgpJyiqJYLFKn01lY5ouic9aLZMZSXCh+1KmTyLJM6sXFHA7opaqSnMkIXcACkzRNG04mExS6o7RaLeOsUDAqlYo5MtzRFiwwzaoQbm1TcinRfjpNN9fXlD44oHA4bFsVT5EKHc/0O8Hx78tlOsxmqXx3N0+LyAcsMF18Jg1v30msnCq5HKVSKcKInEMvErDARCpUNBSREXS3pZIJs46P8UhRTL3IZ8ZSV/dA0E859Dy6lCiCZXRggAWmWRXop2h96FLLgOy28AUDLOhNMJo0P0eZL6T7F7itbcpWw5/3Y+yykkYPMGQlv6Ypevr978/0AyH3oBomU94UAAAAAElFTkSuQmCC) no-repeat;
}

/* ------------------MEDIA QUERY SECTION ------------------------- */
/* Media query for tablets and screens higher */
@media (min-width: 768px) {
}


/* sreens higher than tablets */
@media (min-width: 1024px) {
    .trade_filter_container{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding: 25px 20px 0 0;
    }
    .input_style{
        width: 500px;
    }
    .details_card{
        background: #FFFFFF;
        box-shadow: 0px 30px 70px rgba(26, 55, 78, 0.2);
        border-radius: 10px;
        padding: 50px 80px;
        max-width: 48rem;
        margin: auto;
    }
    .tab_div{
        margin-top: 10px;
        background: rgba(247, 147, 26, 0.05);
        border: 1px solid rgba(26, 55, 78, 0.1);
        box-sizing: border-box;
        border-radius: 10px;
        padding: 5px 8px;
        width: 300px;
    }

}



/* large screens */
@media (min-width: 1536px) {
}



.trades_div{
    background: #fff;
    border-radius: 12px;
    box-shadow: 0px 30px 70px rgba(26, 55, 78, 0.2);
    padding: 10px;
}

.recent_table_row{
    color: rgba(26, 55, 78, 0.9);
    font-family: 'Averta';
}


.title{
    color: rgb(45, 45, 45);
}

.details_card{
    background: #FFFFFF;
    box-shadow: 0px 30px 70px rgba(26, 55, 78, 0.2);
    border-radius: 10px;
    padding: 40px 20px;
}

.goback_div{
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #F7931A;
    text-decoration: none;
}

.goback_div:hover{
    color: #F7931A;
    text-decoration: none;
}

.profile_div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}

.userImage{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1.5px solid #ff8400;
    padding: 5px;
}

.title{
    color: rgb(45, 45, 45);
}

.userInfoDiv{
    background: rgba(44, 58, 80, 0.2);
    border-radius: 5px;
    padding: 10px 20px;
}

.userInfo{
    margin-bottom: 0px;
    text-align: center;
    color: rgb(44, 58, 80);
}

.accountDiv{
    background: rgba(44,58,80,.2);
    border-radius: 5px;
    padding: 15px 20px;
    height: auto;
}

.accountInfo{
    margin-bottom: 0px;
    color: rgb(44, 58, 80);  
}

.btn_suspend{
    color: #fff;
    border-radius: 5px;
    background: #F7931A;
    padding: 12px 30px;
    transition: all .15s ease-in-out;
    font-weight: 700;
}

.btn_suspend:hover{
    color: #fff;
    background: rgba(247, 147, 26, 0.9);
}

.btn_restore{
    color: #fff;
    border-radius: 5px;
    background: #1A374E;
    padding: 12px 30px;
    transition: all .15s ease-in-out;
    font-weight: 700;  
}

.btn_restore:hover{
    color: #fff;
    background: rgba(26, 55, 78, 0.9);
}


/* ------------------MEDIA QUERY SECTION ------------------------- */
/* Media query for tablets and screens higher */
@media (min-width: 768px) {
}


/* sreens higher than tablets */
@media (min-width: 1024px) {

    .details_card{
        background: #FFFFFF;
        box-shadow: 0px 30px 70px rgba(26, 55, 78, 0.2);
        border-radius: 10px;
        padding: 50px 80px;
        max-width: 48rem;
        margin: auto;
    }

    .profile_div{
        flex-direction: row;
    }

    
}



/* large screens */
@media (min-width: 1536px) {
}
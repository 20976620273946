
.TabHeading{
    display: flex;
    justify-content: center;
}

.activeTab{
    font-weight: 600;
    position: relative;
    cursor: pointer;
}

.activeTab:after {
    content: "";
    height: 2px;
    width: 100%;
    background: #1A374E;
    position: absolute;
    left: 0;
    bottom: -8px;
    transition: .5s;
}

.tab{
    margin-right: 20px;
    cursor: pointer;
}

.rates_card{
    background-color: #fff;
    border-radius: 10px;
    padding: 40px;
    box-shadow: 0px 30px 70px rgba(26, 55, 78, 0.1);
}

.title{
    text-align: center;
    color: rgba(26, 55, 78, 0.9);
}


.input_container label{
    font-size: 12px;
    color:  rgba(26, 55, 78, 0.8);
    font-family: 'Averta';
}

.input_style{
    border: 1px solid #F4F2F2;
    border-radius: 6px;
    font-size: 14px;
    color: #1A374E;
    width: 100%;
    padding: 14px 20px;
    background: #fff;
    appearance: none;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.input_style:focus{
    border: 1px solid #1A374E;
    background: white;
    box-shadow: none;
    outline: 0 none;
  }
  
  .input_style::placeholder{
    color:rgba(26, 55, 78, 0.5);
    font-size: 14px;
    font-family: 'Averta';
  }

  .btn_add{
    padding: 14px 40px;
    background: #1A374E;
    border: 1px solid #1A374E;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    width: 100%;
    font-family: 'GraphixMedium';
  }

  .btn_add:hover{
    color: #fff;
    background: rgba(26, 55, 78, 0.9);
  }

.ratestables_div{
    background: #fff;
    border-radius: 12px;
    box-shadow: 0px 30px 70px rgba(26, 55, 78, 0.2);
    padding: 10px;
}

.recent_table_row{
    color: rgba(26, 55, 78, 0.9);
    font-family: 'Averta';
}


.goback_div{
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #F7931A;
    text-decoration: none;
}

.goback_div:hover{
    color: #F7931A;
    text-decoration: none;
}


/* ------------------MEDIA QUERY SECTION ------------------------- */
/* Media query for tablets and screens higher */
@media (min-width: 768px) {
}


/* sreens higher than tablets */
@media (min-width: 1024px) {  
 .admin_card{
     background: #FFFFFF;
     box-shadow: 0px 30px 70px rgba(26, 55, 78, 0.2);
     border-radius: 10px;
     padding: 40px 80px;
     max-width: 48rem;
     margin: auto;
 }
}



/* large screens */
@media (min-width: 1536px) {
}